import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart } from 'actions/user';
import PropTypes from 'prop-types';
import { openLoginModal } from 'actions/loginModal';

const ProductPaymentButton = ({ quantity, productEanid, productRef, isAddedToCart, name }) => {
  const dispatch = useDispatch();
  const _loading = useSelector(({ loading }) => loading.loading);
  const { isLogged } = useSelector(({ auth }) => auth);

  return (
    <>
      <div id="shopCartAccordion" className="accordion mb-5" />
      <div className="mb-4">
        {isAddedToCart ? (
          <div className="card-footer border-0 pt-0 pb-4 px-4 text-center">
            <button type="button" className="btn btn-sm btn-outline-secondary btn-pill disabled">
              Dans le panier
            </button>
          </div>
        ) : (
          <div className="card-footer border-0 pt-0 pb-4 px-4 text-center">
            <button
              onClick={() =>
                isLogged
                  ? dispatch(addItemToCart(productEanid, productRef, quantity, name))
                  : dispatch(openLoginModal(true))}
              type="button"
              className="btn btn-sm btn-outline-primary btn-pill transition-3d-hover"
              disabled={_loading}
            >
              Ajouter au panier
            </button>
          </div>
        )}
      </div>
    </>
  );
};

ProductPaymentButton.propTypes = {
  quantity: PropTypes.number,
  productRef: PropTypes.string,
  productEanid: PropTypes.string,
  isAddedToCart: PropTypes.bool,
  name: PropTypes.string,
};
export default ProductPaymentButton;
