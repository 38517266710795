import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart } from 'actions/user';
import { toTitleCase } from 'helpers/toTitleCase';
import { getImageUrl } from 'helpers/getImageUrl';
import { slugifyString } from 'helpers/slugifyString';
import ProductGridCard from 'components/ProductGridCard';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { RED_TAG } from 'constants/tags';
import { openLoginModal } from 'actions/loginModal';

const BestSellersCarousel = ({ articles, tableOfRef }) => {
  const dispatch = useDispatch();

  const { isLogged } = useSelector(({ auth }) => auth);
  const handleAddItemToCart = (id, ref, title) => () => {
    if (isLogged) {
      dispatch(addItemToCart(id, ref, 1, title));
    } else {
      dispatch(openLoginModal(true));
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: articles.length <= 2 ? articles.length : 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4000,
          dots: true,
        },
      },
    ],
  };

  return (
    <div>
      <div id="SVGdoubleEllipseTopRight" className="svg-preloader position-relative bg-light">
        <div className="container space-2 position-relative z-index-2">
          <div className="row">
            <div className="col-lg-4 mb-7 mb-lg-0">
              <div className="justify-content-center text-center w-100 pr-4 pt-9">
                <h2 className="text-primary font-size-5">Recommandés pour vous . . .</h2>
              </div>
            </div>
            <div className="col-lg-8">
              <Slider {...settings}>
                {articles &&
                  articles.map((article, index) => (
                    <div className="js-slide my-4 pl-3 max-w-50rem" key={article.eanId}>
                      <ProductGridCard
                        onClick={handleAddItemToCart(article.eanId, article.reference, article.title)}
                        quantity={article.quantity}
                        redTag={RED_TAG}
                        buttonText="Ajouter au panier"
                        buttonExistText="Dans le panier"
                        title={toTitleCase(article.title)}
                        category={article.brand}
                        image={`${getImageUrl(article.eanId)}`}
                        price={article.price}
                        unit="TND"
                        discount={10}
                        productLink={`/products/${slugifyString(article.title.replace(/\//g, '%2F'))}`}
                        isAddedToCart={tableOfRef.includes(article.eanId.trim())}
                      />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BestSellersCarousel.propTypes = {
  articles: PropTypes.array,
  tableOfRef: PropTypes.array,
};
export default BestSellersCarousel;
