/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';

const ProductQuantity = ({ quantity, onChange }) => {
  const [qte, setQte] = useState(1);
  const isLoading = useSelector(({ loading }) => loading.loading);

  const handleChange = value => () => {
    onChange(value);
    setQte(value);
  };

  return (
    <>
      {!isLoading ? (
        <>
          <h2 className="font-size-1 text-body mb-0">Quantité:</h2>
          <div className="border rounded py-2 px-3 mt-2">
            <div className="js-quantity-counter row align-items-center">
              <div className="col-7"> {qte} </div>
              <div className="col-5 text-right">
                {qte < quantity ? (
                  <div onClick={handleChange(qte + 1)} style={{ cursor: 'pointer' }}>
                    <i className="fas fa-plus " />{' '}
                  </div>
                ) : (
                  <div style={{ color: '#f2eee9' }}>
                    <i className="fas fa-plus " />{' '}
                  </div>
                )}
                {qte > 1 ? (
                  <div style={{ cursor: 'pointer' }} onClick={handleChange(qte - 1)}>
                    <i className="fas fa-minus pointer" />
                  </div>
                ) : (
                  <div style={{ color: '#f2eee9' }}>
                    <i className="disabled fas fa-minus" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Skeleton animation="wave" />
      )}

      {!isLoading ? (
        <div />
      ) : (
        // <h3 className="font-size-1 text-body mb-3">Quantité disponible({quantity})</h3>
        <Skeleton animation="wave" />
      )}
    </>
  );
};

ProductQuantity.propTypes = {
  quantity: PropTypes.number,
  onChange: PropTypes.func,
};
export default ProductQuantity;
