/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SingleProductContainer from 'containers/SingleProductContainer';
import { Link } from 'react-router-dom';
import BreadCrumb from 'components/Breadcrumb';
import BreadCrumbCategory from 'components/BreadCrumbCategory';
import PropTypes from 'prop-types';
import SimilarArticlesCarousel from 'containers/SimilarArticlesCarousel';
import { useSelector } from 'react-redux';

const SingleProductPage = ({ paths, pathsCategory }) => {
  const { similarArticles } = useSelector(state => state.product);

  return (
    <>
      <div className="bg-light">
        <div className="container py-5">
          <div className="row align-items-sm-center">
            <div className="col-sm-6 mb-3 mb-sm-0">
              <div className="h4 mb-0">Produits</div>
            </div>
            <BreadCrumb paths={paths} />
            {pathsCategory && <BreadCrumbCategory paths={pathsCategory} col="12" />}
          </div>
        </div>
      </div>
      <div className="container space-top-1 space-top-md-2 space-bottom-1 space-bottom-lg-2">
        Détail du produit
        <SingleProductContainer />
        {similarArticles && (
          <>
            <h2>Articles Similaires: </h2>
            <SimilarArticlesCarousel articles={similarArticles} />
          </>
        )}
        <div className="d-sm-flex justify-content-start m-10">
          <Link className="font-weight-bold" to="/products">
            <i className="fas fa-angle-left fa-xs mr-1" />
            Continuez votre shopping
          </Link>
        </div>
      </div>
    </>
  );
};

SingleProductPage.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.string),
  pathsCategory: PropTypes.arrayOf(PropTypes.string),
};
export default SingleProductPage;
