import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
// import HeroesTitleSVG from './HeroesTitleSVG';

const HeroesHomePage = ({ heroes }) => {
  return (
    <>
      <div className="justify-content-center text-center w-100 pl-2 pt-4 ">
        <div className="position-relative text-center d-flex justify-content-center">
          {/* <HeroesTitleSVG /> */}
          <div
            className="text-primary text-center font-weight-bold font-size-2 position-absolute"
            style={{ width: '50%', top: '41%' }}
          >
            Leurs héros préférés
          </div>
        </div>
      </div>
      <div className="container space-top-1 space-top-md-2 space-bottom-1">
        <div className="row justify-content-center">
          {heroes.map(o => (
            <div className="col-lg-2 col-md-4 col-6 justify-content-center" key={o.name}>
              <Link key={o.name} to={`/products${o.searchKey}`}>
                <>
                  <div className="mb-3">
                    <ImageAsyncComponent image={o.image} />

                    <span className="d-block h3 text-center pt-4">{o.name}</span>
                  </div>
                </>
              </Link>
            </div>
          ))}
        </div>
        <Link to="/heroes" className="d-flex justify-content-center">
          <div className="btn btn-soft-indigo ">Voir tous les héros</div>
        </Link>
      </div>
    </>
  );
};

HeroesHomePage.propTypes = {
  heroes: PropTypes.array,
};
export default HeroesHomePage;
