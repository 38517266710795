import React from 'react';
import Slider from 'react-slick';
import ProductGridCard from 'components/ProductGridCard';
import { addItemToCart } from 'actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { toTitleCase } from 'helpers/toTitleCase';
import { slugifyString } from 'helpers/slugifyString';
import { RED_TAG } from 'constants/tags';
import PropTypes from 'prop-types';
import { getImageUrl } from 'helpers/getImageUrl';
import { openLoginModal } from 'actions/loginModal';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 3,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
      },
    },
  ],
};
const SimilarArticlesCarousel = ({ articles }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ userReducer }) => userReducer);
  const tableOfRef = [];

  if (user) {
    // eslint-disable-next-line no-unused-expressions
    user?.user?.carts?.forEach(cart => {
      tableOfRef.push(cart.productEanId.trim());
    });
  }
  const { isLogged } = useSelector(({ auth }) => auth);
  const handleAddItemToCart = (id, ref, title) => () => {
    if (isLogged) {
      dispatch(addItemToCart(id, ref, 1, title));
    } else {
      dispatch(openLoginModal(true));
    }
  };

  return (
    <Slider {...settings}>
      {articles &&
        articles.map(article => (
          <div className="js-slide my-4 pl-3 max-w-50rem" key={article.eanId}>
            <ProductGridCard
              onClick={handleAddItemToCart(article.eanId, article.reference, article.title)}
              quantity={article.quantity}
              redTag={RED_TAG}
              buttonText="Ajouter au panier"
              buttonExistText="Dans le panier"
              title={toTitleCase(article.title)}
              category={article.brand}
              image={`${getImageUrl(article.eanId)}`}
              price={article.price}
              unit="TND"
              discount={!article?.hasNoDiscount ? 10 : undefined}
              productLink={`/products/${slugifyString(article.title.replace(/\//g, '%2F'))}`}
              isAddedToCart={tableOfRef.includes(article.eanId.trim())}
            />
          </div>
        ))}
    </Slider>
  );
};

SimilarArticlesCarousel.propTypes = {
  articles: PropTypes.array,
};
export default SimilarArticlesCarousel;
