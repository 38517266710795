/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import BrandsHomePage from 'components/BrandsHomePage';
import SafeAreaView from 'components/SafeAreaView';
import AgeCardsHomePage from 'components/AgeCardsHomePage';
import BestSellersCarousel from 'components/BestSellersCarousel';
import HeroesHomePage from 'components/HeroesHomePage';
import HeroSectionContainer from 'containers/HeroSectionContainer';
import PropTypes from 'prop-types';

const Home = ({ bestSellers, bestBrands, ages, bestHeroes, tableOfRef }) => {
  return (
    <>
      <SafeAreaView>
        <br />
        <br />
        <HeroSectionContainer />
        <br />
        <h1 className="text-primary text-center">Moncef Jouets, Une grande histoire de générosité</h1>
        <br />
        <hr />
        <h2 className="text-primary text-center">Classification des produits par âge</h2>
        <AgeCardsHomePage content={ages} />
        {bestSellers && <BestSellersCarousel articles={bestSellers} tableOfRef={tableOfRef} />}
        <HeroesHomePage heroes={bestHeroes} />
        <hr />
        <br />
        <h2 className="text-primary text-center">Nos marques</h2>
        <BrandsHomePage brands={bestBrands} />
      </SafeAreaView>
    </>
  );
};

Home.propTypes = {
  bestSellers: PropTypes.arrayOf(PropTypes.any),
  ages: PropTypes.arrayOf(PropTypes.any),
  bestHeroes: PropTypes.arrayOf(PropTypes.any),
  bestBrands: PropTypes.arrayOf(PropTypes.any),
  tableOfRef: PropTypes.arrayOf(PropTypes.any),
};

export default Home;
