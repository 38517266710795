import React from 'react';
import PropTypes from 'prop-types';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const HeroSectionComponent = ({ heroSec }) => {
  return (
    <>
      <div className="align-items-center justify-content-between d-flex display-media-big">
        <div className="container hero-section  d-flex justify-content-center">
          <a className=" col-12 " href={heroSec.link1} target="_blank">
            <div
              className="bg-img-hero first-container position-relative w-100 h-100"
              style={{
                backgroundImage: `url(https://api.moncefjouets.com/api/v1/${heroSec.image1})`,
                backgroundPositionY: 'center',
              }}
            >
              <div
                className="position-absolute text-white"
                style={{
                  bottom: '10px',
                  right: '10px',
                }}
              >
                <ExitToAppIcon />
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

HeroSectionComponent.propTypes = {
  heroSec: PropTypes.any,
};
export default HeroSectionComponent;
