/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getImageUrl } from 'helpers/getImageUrl';
import ImageSlider from 'components/ImageSlider';
import loadImage from 'image-promise';
import ProductTitleAndDescription from 'components/ProductTitleAndDescription';
import ProductPrice from 'components/ProductPrice';
import ProductQuantity from 'components/ProductQuantity';
import ProductStock from 'components/ProductStock';
import ProductPaymentButton from 'components/ProductPaymentButton';
import { useHeader } from 'hooks/useHeader';
import Skeleton from '@material-ui/lab/Skeleton';
import * as ReactGA from 'react-ga';

const SingleProductContainer = () => {
  const isLoading = useSelector(({ loading }) => loading.loading);
  const { article } = useSelector(({ product }) => product);
  const { user } = useSelector(({ userReducer }) => userReducer);
  const tableOfRef = [];

  if (user) {
    // eslint-disable-next-line no-unused-expressions
    user?.user?.carts?.forEach(cart => {
      tableOfRef.push(cart.productEanId.trim());
    });
  }
  const [articlePhotos, setArticlePhotos] = useState([]);
  const [quantity, setquantity] = useState(1);
  const handleQuantity = value => {
    setquantity(value);
  };

  const { brands } = useHeader();

  const articleBrand = brands?.find(element => element?.brandId === article?.brand);

  useEffect(() => {
    setArticlePhotos([]);
  }, []);
  useEffect(() => {
    if (!isLoading) {
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: article.eanId,
        name: article?.title,
        price: article?.price,
      });
      ReactGA.plugin.execute('ec', 'setAction', 'detail');
      ReactGA.send('pageView');
    }
  }, [isLoading]);
  useEffect(() => {
    const _articlePhotos = [];

    for (let index = 0; index < 5; index++) {
      const image = getImageUrl(article?.eanId, index);

      loadImage(image)
        .then(() => {
          _articlePhotos.push({ src: image });
          setArticlePhotos([..._articlePhotos]);
        })
        .catch(() => {});
    }
  }, [article?.eanId, article]);
  const unit = 'TND';

  return (
    <div>
      <div>
        <div className="container space-top-1 space-top-sm-2 space-bottom-1">
          <div className="row">
            <div className="col-lg-6  mb-lg-0" style={{ marginBottom: '50%' }}>
              {isLoading ? (
                <Skeleton variant="rect" width="70%" height="50%" style={{ marginBottom: '50%' }} />
              ) : (
                <ImageSlider style={{ width: '70%', height: '50%', marginBottom: '50%' }} photos={articlePhotos} />
              )}
            </div>
            <div className="col-lg-5">
              {!isLoading && (
                <>
                  <ProductTitleAndDescription
                    title={article?.title}
                    description={article?.description}
                    brand={articleBrand}
                    minAge={article?.min_age_month}
                  />
                  <ProductPrice
                    price={article?.price}
                    discount={!article?.hasNoDiscount ? 10 : undefined}
                    unit={unit}
                  />
                  <hr />
                </>
              )}
              <div>
                {!isLoading ? (
                  <span style={{ fontSize: '0.7rem' }}>
                    SA_REF : {article?.SAREF1}
                    <br />
                    EAN : {article?.eanId}
                  </span>
                ) : (
                  <Skeleton animation="wave" />
                )}
              </div>
              <hr />
              <ProductQuantity quantity={article?.quantity} onChange={handleQuantity} />
              <ProductStock article={article} />
              {!isLoading ? (
                <ProductPaymentButton
                  quantity={quantity}
                  productEanid={article?.eanId}
                  productRef={article?.reference}
                  name={article?.title}
                  isAddedToCart={tableOfRef.includes(article?.eanId?.trim())}
                />
              ) : (
                <Skeleton animation="wave" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProductContainer;
