import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toTitleCase } from 'helpers/toTitleCase';

const BreadCrumbCategory = ({ paths, col = 6 }) => {
  return (
    <div className={`col-sm-${col}`}>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb breadcrumb-no-gutter justify-content-sm-end mb-0">
          {paths.map((p, i) => {
            const searchKey =
              // eslint-disable-next-line no-nested-ternary
              i === 0
                ? p.title
                : i === 1
                ? `${paths[i - 1]?.title} > ${p.title}`
                : `${paths[i - 2]?.title} > ${paths[i - 1]?.title} > ${p.title}`;

            return (
              <li key={p.title} className="breadcrumb-item">
                <Link
                  to={`/products?category${i}=${JSON.stringify({ label: decodeURI(searchKey) })}`}
                  // to={`/products${searchStateToUrl({
                  //   hierarchicalMenu: {
                  //     'categories.category.lvl0': searchKey,
                  //   },
                  // })}`}
                >
                  {toTitleCase(p.title)}
                </Link>
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

BreadCrumbCategory.propTypes = {
  paths: PropTypes.array,
  col: PropTypes.string,
};

export default BreadCrumbCategory;
