/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import formatPrice from 'helpers/formatPrice';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';

const ProductPrice = ({ price, discount, unit }) => {
  const isLoading = useSelector(({ loading }) => loading.loading);
  const priceAfterDiscount = (discount ? price - (price * 1) / discount : price).toFixed(2);

  return (
    <>
      <div className="mb-5 d-flex flex-row">
        {!isLoading ? <h2 className="font-size-1 text-body mb-0">Prix : </h2> : <Skeleton animation="wave" />}
        {!isLoading ? (
          <div className="d-block text-center d-flex flex-column ml-5">
            <span className="font-weight-bold" style={{ color: '#444385' }}>
              {formatPrice(priceAfterDiscount, unit)}
            </span>
            {discount && (
              <span className="text-body ml-1">
                <del>{formatPrice(price, unit)}</del>
              </span>
            )}
          </div>
        ) : (
          // <span className="text-dark font-size-2 font-weight-bold">{formatPrice(price?.toFixed(2), unit)}</span>
          <Skeleton animation="wave" />
        )}
      </div>
    </>
  );
};

ProductPrice.propTypes = {
  price: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
};
export default ProductPrice;
