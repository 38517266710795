import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import ImageAsyncComponentForThumbnail from 'components/ImageAsyncComponentForThumbnail';
// import getImage from 'helpers/getImage';

const ImageSlider = ({ photos }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav',
  };

  const settingsThumbs = {
    slidesToShow: photos.length < 3 ? photos.length : 3,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    arrows: false,
  };

  return (
    <>
      {photos.length > 0 ? (
        <>
          <Slider {...settings} asNavFor={nav2} ref={slider => setSlider1(slider)}>
            {photos.map(element => {
              return <ImageAsyncComponent image={element.src} fallback={element.fallback} alt="slider" key={element} />;
            })}
          </Slider>
          <div className="thumbnail-slider-wrap">
            {photos.length > 1 && (
              <Slider
                {...settingsThumbs}
                asNavFor={nav1}
                ref={slider => setSlider2(slider)}
                style={{ borderRadius: '0.25rem' }}
              >
                {photos.map((element, index) => {
                  return (
                    <div key={`${element.src} ${index + 1}`} style={{ borderRadius: '0.25rem' }}>
                      <ImageAsyncComponentForThumbnail
                        style={{ borderRadius: '0.25rem' }}
                        image={element.src}
                        fallback={element.fallback}
                        alt="slider"
                        key={element}
                      />
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        </>
      ) : (
        <ImageAsyncComponent />
      )}
    </>
  );
};

ImageSlider.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({ src: PropTypes.string.isRequired, fallback: PropTypes.string }).isRequired,
  ).isRequired,
};

export default ImageSlider;
