import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import ImageAsyncComponent from 'components/ImageAsyncComponent';

const BrandsHomePage = ({ brands }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    slidesToShow: 4,
    autoplay: true,
    arrows: false,
    // autoplaySpeed: 50,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false,
        },
      },
      // {
      //   settings: {
      //     slidesToShow: 1,
      //     infinite: true,
      //     autoplay: true,
      //     autoplaySpeed: 4000,
      //     dots: false,
      //   },
      //   // breakpoint: 600,
      // },
    ],
  };

  return (
    <>
      <div className="container space-top-1 space-top-md-2 space-bottom-2 space-bottom-lg-3">
        <Slider {...settings}>
          {brands.map(o => (
            <div key={o.searchKey}>
              <Link to={`/products${o.searchKey}`} key={o.name}>
                <div className="card mb-3 mx-5">
                  <ImageAsyncComponent image={o.image} />
                </div>
              </Link>
            </div>
          ))}
        </Slider>
        <Link to="/brands" className="d-flex d-flex justify-content-center">
          <div className="btn btn-soft-indigo mt-3">Voir toutes les marques</div>
        </Link>
      </div>
    </>
  );
};

BrandsHomePage.propTypes = {
  brands: PropTypes.array,
};
export default BrandsHomePage;
