/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { listMailCanSeeQte } from 'constants/listOfMailsAccess';

const ProductStock = ({ article }) => {
  const isLoading = useSelector(({ loading }) => loading.loading);
  const { user } = useSelector(({ userReducer }) => userReducer);

  return (
    <>
      {!isLoading ? (
        <div>
          {article &&
            (article?.quantity > 0 ? (
              <div className="border rounded py-2 px-3 mb-3">
                <div className="js-quantity-counter row align-items-center">
                  <div className="col-7">
                    <h4>{`${article?.lib_depot_1} :`}</h4>
                    <h4>{`${article?.lib_depot_2} :`}</h4>
                  </div>
                  <div className="col-5 text-right">
                    {article.quantity_depot_1 > 0 ? (
                      <h4 className="text-success">
                        Disponible
                        {listMailCanSeeQte.includes(user?.user?.email?.trim()) ? `(${article?.quantity_depot_1})` : ''}
                      </h4>
                    ) : (
                      <h4 className="text-danger">Non Disponible</h4>
                    )}
                    {article.quantity_depot_2 > 0 ? (
                      <h4 className="text-success">
                        Disponible
                        {listMailCanSeeQte.includes(user?.user?.email?.trim()) ? `(${article?.quantity_depot_2})` : ''}
                      </h4>
                    ) : (
                      <h4 className="text-danger">Non Disponible</h4>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="border rounded py-2 px-3 mb-3">
                <div className="js-quantity-counter row align-items-center">
                  <h4 className="text-danger">Non disponible actuellement dans nos magasins</h4>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <Skeleton animation="wave" />
      )}
    </>
  );
};

ProductStock.propTypes = {
  article: PropTypes.object.isRequired,
};

export default ProductStock;
