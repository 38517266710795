import React from 'react';
import Slider from 'react-slick';
import HeroSectionComponent from 'components/HeroSectionComponent';
import { useSelector } from 'react-redux';

const HeroSectionContainer = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2200,
    autoplaySpeed: 8000,
    autoplay: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4000,
          dots: true,
        },
      },
    ],
  };
  const { heroSection } = useSelector(({ heroSectionReducer }) => heroSectionReducer);

  return (
    <div className="container">
      <Slider {...settings}>
        {heroSection?.allHeroSection
          ?.sort((a, b) => b.id - a.id)
          .map(heroSec => {
            return (
              <div key={heroSec.link1} className="js-slide">
                <HeroSectionComponent heroSec={heroSec} />
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default HeroSectionContainer;
