import React from 'react';
import AccessibilityNewRoundedIcon from '@material-ui/icons/AccessibilityNewRounded';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const AgeCardsHomePage = ({ content }) => {
  const fontSize = [20, 25, 30, 35, 40, 45];
  const top = ['18px', '14px', '11px', '7px', '5px', '2px'];
  const left = ['20px', '14px', '14px', '13px', '7px', '7px'];

  return (
    <div className="container space-top-1 space-top-md-2 space-bottom-2 space-bottom-lg-2">
      <div className="row ">
        {content.map((o, index) => (
          <div
            className="col-lg-2 customAgeCards col-md-4 col-sm-6 col-xs-12 justify-content-center my-2 "
            key={o.name}
          >
            <Link to={`/products${o.searchKey}`} key={o.name} className=" mb-3 mb-sm-3">
              <div
                className="btn btn-outline-secondary  shadow-soft btn-pill btn-lg position-relative"
                style={{ minWidth: '150px' }}
              >
                <div className="d-flex ">
                  <AccessibilityNewRoundedIcon
                    style={{
                      fill: '#14259b',
                      position: 'absolute',
                      top: top[index],
                      left: left[index],
                      fontSize: fontSize[index],
                    }}
                  />

                  <div className="justify-content-center text-center text-primary w-100 pl-2">
                    {(o.name && o.name.replace(')', '').replace('(', '')) || ''}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

AgeCardsHomePage.propTypes = {
  content: PropTypes.array,
};
export default AgeCardsHomePage;
