/* eslint-disable no-nested-ternary */
/* eslint-disable no-extra-parens */
/* eslint-disable react/no-danger */
import React from 'react';
import { formatStringToHtml } from 'helpers/formatStringToHtml';
import TextConverter from 'helpers/rtfConverter';
import PropTypes from 'prop-types';
// import ImageAsyncComponent from 'components/ImageAsyncComponent';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';

const ProductTitleAndDescription = ({ title, description, brand, minAge }) => {
  const convert = new TextConverter();
  const isLoading = useSelector(({ loading }) => loading.loading);
  const createMarkup = () => {
    return {
      __html: formatStringToHtml(
        convert.convert(
          description
            ?.replace(/\\'e9/g, 'é')
            ?.replace(/\\'e2/g, 'â')
            ?.replace(/\\'e8/g, 'è')
            ?.replace(/\\'c8/g, 'è')
            ?.replace(/\\'8c/g, 'Œ')
            ?.replace(/\\'ef/g, 'ï')
            ?.replace(/\\'ea/g, 'ê')
            ?.replace(/\\'fb/g, 'û')
            ?.replace(/\\'e0/g, 'à')
            ?.replace(/\\'e7/g, 'ç'),
        ),
      ),
    };
  };

  return (
    <>
      <div className="mb-5">
        {!isLoading ? <h1 className="h2">{title}</h1> : <Skeleton animation="wave" />}
        <div className="brand-container d-flex align-items-center">
          {!isLoading && brand && (
            <Link to={`/products${brand.searchKey}`}>
              <div className="" style={{ textDecoration: 'underline' }}>
                {brand?.name}
              </div>
            </Link>
          )}
        </div>
      </div>
      <div>
        {isLoading ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <>
            <b>
              {minAge === 0
                ? 'Pour tout âge'
                : minAge < 18
                ? `Â partir de ${minAge} mois.`
                : `Â partir de ${minAge / 12} ans.`}
            </b>
            <br />
            <br />
            {description && <div dangerouslySetInnerHTML={createMarkup()} />}
          </>
        )}
        <br />
      </div>
    </>
  );
};

ProductTitleAndDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  brand: PropTypes.object,
  minAge: PropTypes.any,
};
export default ProductTitleAndDescription;
